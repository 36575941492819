<template>
<div id="user-profile">
    <b-row>
        <b-col lg="5" cols="12" order="1" order-lg="1">
            <b-card class="profile-header mb-2" body-class="p-0">
                <b-card-body>
                    <b-row class="mb-2">
                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Sınıf/Ders</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="
                    (option) => option.class.name + ' / ' + option.lesson.name
                  " :reduce="(val) => val.id" />
                        </b-col>

                        <b-col cols="12" md="6" class="mb-md-0 mb-2">
                            <label>Alt Sınıf</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="childClassId" :options="childClass" class="w-100" :reduce="(val) => val.class" :getOptionLabel="(option) => option.class.name" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" md="6" class="mb-md-0 mb-2">
                            <b-button block variant="success" @click="getStudent()">FİLTRELE</b-button>
                        </b-col>
                        <b-col cols="6" md="6" class="mb-md-0 mb-2">
                            <b-button block variant="info" @click="getDownload()">RAPOR İNDİR</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
            <b-card no-body>
                <vue-good-table theme="polar-bear" :rows="users" :columns="questionFields" :line-numbers="true" :sort-options="{
                        enabled: false,
                    }">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'action'">
                            <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="getReport(props.row)">İzle</b-button>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
        <b-col lg="7" cols="12" order="2" order-lg="2">
            <b-card class="profile-header mb-2" body-class="p-0">
                <b-card-body>
                    <div class="position-relative">
                        <b-row>
                            <b-col lg="2" cols="12" order="1" order-lg="1">
                                <div class="profile-img">
                                    <b-img :src="selectedUser.profileImage" style="width: 126px; height: 126px; object-fit: contain" rounded fluid alt="profile photo" />
                                </div>
                            </b-col>
                            <b-col lg="4" cols="12" order="1" order-lg="1">
                                <div class="profile-title">
                                    <h2 class="text-black">
                                        {{ selectedUser.firstName }} {{ selectedUser.lastName }}
                                    </h2>
                                    <span> Telefon Numarası : {{ selectedUser.gsmNumber }}</span><br />
                                    <span> E-Mail Hesabı : {{ selectedUser.email }}</span>
                                    <p class="text-black">
                                        Sınıfı :
                                        {{ childClassId != null ? childClassId.name : "-" }}
                                    </p>
                                    <span>
                                        Okul Numarası : {{ selectedUser.schoolNumber }}</span>
                                </div>
                            </b-col>
                            <b-col lg="6" cols="12" order="1" order-lg="1">
                                <div class="profile-title" v-if="guardian!=null">
                                    <span>
                                        Veli Bilgileri
                                    </span>
                                    <h2 class="text-black">
                                        {{ guardian.firstName }} {{ guardian.lastName }}
                                    </h2>
                                    <span> Telefon Numarası : {{ guardian.gsmNumber }}</span><br />
                                    <span> E-Mail Hesabı : {{ guardian.email }}</span>
                                </div>
                            </b-col>
                            <b-col lg="12" cols="12" order="1" order-lg="1">
                                <b-row class="mb-2">
                                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                        <label>Ders</label>
                                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="filteredLesson" :options="lessons" class="w-100" :getOptionLabel="(option) => option.lesson.name" :reduce="(val) => val" />
                                    </b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                        <label>Alt Ders</label>
                                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="filteredChildLesson" :options="childLessons" class="w-100" :reduce="(val) => val" :getOptionLabel="(option) => option.name" />
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="position-relative" style="justify-content: center">
                            <b-tabs pills class="profile-tabs mt-2 mt-md-0" nav-class="mb-0">
                                <template #tabs-start>
                                    <b-nav-item role="presentation" :active="selectedMenu == 0" class="font-weight-bold" @click="selectedMenu = 0">
                                        <span class="d-none d-md-block">▶ Videolar</span>
                                        <feather-icon icon="RssIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 1" class="font-weight-bold" @click="selectedMenu = 1">
                                        <span class="d-none d-md-block">📃 Testler</span>
                                        <feather-icon icon="InfoIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 2" class="font-weight-bold" @click="selectedMenu = 2">
                                        <span class="d-none d-md-block">💣 Eksikler</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 3" class="font-weight-bold" @click="selectedMenu = 3">
                                        <span class="d-none d-md-block">💣 Devamsızlık</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 4" class="font-weight-bold" @click="selectedMenu = 4">
                                        <span class="d-none d-md-block">💣 Ev Ödevleri</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 10" class="font-weight-bold" @click="selectedMenu = 10">
                                        <span class="d-none d-md-block">Planlanmış Ev Ödevleri</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 5" class="font-weight-bold" @click="selectedMenu = 5">
                                        <span class="d-none d-md-block">💣 Deneme Sınavları</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 6" class="font-weight-bold" @click="selectedMenu = 6">
                                        <span class="d-none d-md-block">📨 Sistem Mesajları</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 7" class="font-weight-bold" @click="selectedMenu = 7">
                                        <span class="d-none d-md-block">📨 Öğrenci Durumu</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 8" class="font-weight-bold" @click="selectedMenu = 8">
                                        <span class="d-none d-md-block">📨 Öğrenci Notları</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                    <b-nav-item role="presentation" :active="selectedMenu == 9" class="font-weight-bold" @click="selectedMenu = 9">
                                        <span class="d-none d-md-block">📨 Bildirim Gönderme</span>
                                        <feather-icon icon="ImageIcon" class="d-block d-md-none" />
                                    </b-nav-item>
                                </template>
                            </b-tabs>
                        </b-row>
                    </div>
                </b-card-body>
            </b-card>
            <section id="profile-info">
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 0">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item variant="success" v-for="video in videos" :key="video.video.id">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ video.video.name }}</h6>
                                            <small class="text-muted">{{ video.firstDate }}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <div style="width: 100%" class="mb-1 mb-sm-0">
                                                <b-progress key="success" class="progress-bar-success">
                                                    <b-progress-bar animated :value="video.percent" variant="success">
                                                        <span>İzleme Süresi:
                                                            <strong>{{
                                  new Date(video.time * 1000)
                                    .toISOString()
                                    .substring(14, 19)
                                }}</strong></span>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 1">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <div>
                                    <h3>
                                        Toplam
                                        <span>{{
                        tests.length
                      }}</span>
                                        Test Çözdü
                                    </h3>
                                    <br />
                                </div>
                                <app-timeline>
                                    <app-timeline-item :variant="getTestVariant(test.status)" v-for="test in tests" :key="test.testId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ test.title }}</h6>
                                            <small class="text-muted">{{ test.startDate }}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">D Y B</span>
                                                <div>
                                                    <b-avatar :text="test.correct.toString()" class="mr-50" size="24" variant="light-success" />
                                                    <b-avatar :text="test.wrong.toString()" class="mr-50" size="24" variant="light-danger" />
                                                    <b-avatar :text="test.empty.toString()" class="mr-50" size="24" variant="light-info" />
                                                </div>
                                            </div>
                                            <!-- 2nd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Puan</span>
                                                <span>{{ parseFloat(test.point).toFixed(2) }}</span>
                                            </div>
                                            <!-- 3rd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Bitiş Tarihi</span>
                                                <span>{{ test.endDate }}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 2">
                    <b-card-body>
                        <b-row>
                            <b-button @click="exportExcel"> Indır </b-button>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item :variant="lack.isDelete == true ? 'success' : 'danger'" v-for="lack in lacks" :key="lack.gainId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ lack.title }}</h6>
                                            <small class="text-muted">{{ lack.createdAt }}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">
                                                    {{
                              lack.lesson +
                              " > " +
                              lack.childLesson +
                              " > " +
                              lack.topic +
                              " > " +
                              lack.videoGroup
                            }}
                                                </span>
                                            </div>
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">
                                                    {{ "Eksik Giderme Tarihi : " + lack.deletedAt }}
                                                </span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 3">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <div>
                                    <h3>
                                        Toplam
                                        <span>{{
                        stylabus.filter((x) => x.isActive == false).length
                      }}</span>
                                        Derse Girmedi
                                    </h3>
                                    <h3>
                                        Toplam
                                        <span>{{
                        stylabus.filter((x) => x.isActive == true).length
                      }}</span>
                                        Derse Girdi
                                    </h3>
                                    <br />
                                </div>
                                <app-timeline>
                                    <app-timeline-item :variant="sty.isActive == true ? 'success' : 'danger'" v-for="sty in stylabus" :key="sty.id">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ sty.title }}</h6>
                                            <small class="text-muted">{{
                          sty.day +
                          "." +
                          (sty.month > 9 ? sty.month : "0" + sty.month) +
                          "." +
                          sty.year
                        }}</small>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 4">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <b-row>
                                    <b-col lg="4" cols="4" order="1" order-lg="2">
                                        <h4>
                                            Toplam
                                            <span>{{
                          homeworks.filter((x) => x.status == "Done").length
                        }}</span>
                                            Ödev Yapıldı
                                        </h4>
                                    </b-col>
                                    <b-col lg="4" cols="4" order="1" order-lg="2">
                                        <h4>
                                            Toplam
                                            <span>{{
                          homeworks.filter((x) => x.status == "NotDone").length
                        }}</span>
                                            Ödev Yapılmadı
                                        </h4>
                                    </b-col>
                                    <b-col lg="4" cols="4" order="1" order-lg="2">
                                        <h4>
                                            <span>{{
                          (
                            (100 / homeworks.length) *
                            homeworks.filter((x) => x.status == "Done").length
                          ).toFixed(2)
                        }}</span>
                                            % Yapıldı
                                        </h4>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="8" cols="8" order="1" order-lg="2">
                                        <b-form-input name="firstName" id="name" type="number" v-model="percent" />
                                    </b-col>
                                    <b-col lg="4" cols="4" order="1" order-lg="2">
                                        <b-button block variant="success" @click="setPercent()">HAFTALIK ÖDEV PLANLA</b-button>
                                    </b-col>
                                </b-row>
                                <br />
                                <app-timeline>
                                    <app-timeline-item :variant="getTestVariant(homework.status)" v-for="homework in homeworks" :key="homework.testId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ homework.test.title }}</h6>
                                            <small class="text-muted">{{
                          homework.startDate
                        }}</small>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">D Y B</span>
                                                <div>
                                                    <b-avatar :text="homework.test.correct.toString()" class="mr-50" size="24" variant="light-success" />
                                                    <b-avatar :text="homework.test.wrong.toString()" class="mr-50" size="24" variant="light-danger" />
                                                    <b-avatar :text="homework.test.empty.toString()" class="mr-50" size="24" variant="light-info" />
                                                </div>
                                            </div>
                                            <!-- 2nd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Puan</span>
                                                <span>{{
                            parseFloat(homework.test.point).toFixed(2)
                          }}</span>
                                            </div>
                                            <!-- 3rd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Bitiş Tarihi</span>
                                                <span>{{ homework.endDate }}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 5">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item :variant="getTestVariant(test.status)" v-for="test in exams" :key="test.testId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ test.title }}</h6>
                                            <div class="d-flex flex-column">
                                                <small class="text-muted">{{ test.startDate }}</small>
                                                <small class="text-muted">{{ test.endDate }}</small>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">D Y B</span>
                                                <div>
                                                    <b-avatar :text="test.correct.toString()" class="mr-50" size="24" variant="light-success" />
                                                    <b-avatar :text="test.wrong.toString()" class="mr-50" size="24" variant="light-danger" />
                                                    <b-avatar :text="test.empty.toString()" class="mr-50" size="24" variant="light-info" />
                                                </div>
                                            </div>
                                            <!-- 2nd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50 d-block">Puan</span>
                                                <span>{{ parseFloat(test.point).toFixed(2) }}</span>
                                            </div>
                                            <!-- 3rd Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <b-button @click="openDetail(test.testId)">Detaylar</b-button>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 6">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <app-timeline>
                                    <app-timeline-item variant="success" v-for="systemMessage in systemMessages" :key="systemMessage.testId">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ systemMessage.message }}</h6>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">{{ systemMessage.type }} |
                                                    {{ systemMessage.createdTime }}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 7">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <b-button class="mb-2" @click="getStatus()">YENİLE</b-button>
                                <app-timeline>
                                    <app-timeline-item variant="success">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ userStatus }}</h6>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">Yes AI | {{ new Date() }}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 8">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <b-button class="mb-2" @click="addNotePanel = true">Not Ekle</b-button>
                                <app-timeline>
                                    <app-timeline-item :variant="getNoteStatus(note.status)" v-for="note in notes" :key="note.id">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ note.note }}</h6>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <div class="mb-1 mb-sm-0 text-center">
                                                <span class="text-muted mb-50">{{
                            note.createdTime
                          }}</span>
                                            </div>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 9">
                    <b-card-body>
                        <b-row class="mb-2">
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Mesaj</label>
                                <b-form-textarea v-model="addSendMessageRequest.text" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <b-form-checkbox v-model="addSendMessageRequest.isUser">
                                    Öğrenciye Gönder
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="addSendMessageRequest.isGuardian">
                                    Veliye Gönder
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="addSendMessageRequest.isEmail">
                                    Mail Gönder
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox v-model="addSendMessageRequest.isSms">
                                    Sms Gönder
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-button block variant="primary" class="font-weight-bolder mb-12" @click="sendMessage">Gönder</b-button>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="profile-header mb-2" body-class="p-0" v-if="selectedMenu == 10">
                    <b-card-body>
                        <b-row>
                            <b-col lg="12" cols="12" order="1" order-lg="2">
                                <b-button variant="danger" class="mb-2" @click="allRemoveWeekly">Hepsini Temizle</b-button>
                                <app-timeline>
                                    <app-timeline-item variant="success" v-for="schomework in schedularHomeworks" :key="schomework.id">
                                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                            <h6>{{ schomework.title }} ({{ schomework.exams.length}} Adet Test Planlandı)</h6>
                                            <b-button variant="danger" @click="removeWeekly(schomework.id)">SİL</b-button>
                                        </div>
                                        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                                            <!-- 1st Col -->
                                            <b-col lg="4" cols="4" order="1" order-lg="2">
                                                <div class="mb-1 mb-sm-0 text-center">
                                                    <span>Gönderilme :</span>
                                                    <span class="text-muted mb-50">{{ schomework.sendingTime }}</span>
                                                </div>
                                            </b-col>

                                            <b-col lg="4" cols="4" order="1" order-lg="2">
                                                <div class="mb-1 mb-sm-0 text-center">
                                                    <span>Hatırlatma :</span>
                                                    <span class="text-muted mb-50">{{ schomework.noticeTime }}</span>
                                                </div>
                                            </b-col>

                                            <b-col lg="4" cols="4" order="1" order-lg="2">
                                                <div class="mb-1 mb-sm-0 text-center">
                                                    <span>Durum :</span>
                                                    <span class="text-muted mb-50">{{ schomework.lastNoticeTime }}</span>
                                                </div>
                                            </b-col>
                                        </div>
                                    </app-timeline-item>
                                </app-timeline>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </section>
            <b-overlay :show="loading" no-wrap> </b-overlay>
        </b-col>
    </b-row>
    <b-modal v-model="addNotePanel" title="Öğrenci Not Ekleme" hide-footer>
        <p>
            <b-row class="mb-2">
                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <label>Öncelik</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="addNoteRequest.status" :options="noteStatus" class="w-100" :getOptionLabel="(option) => option.label" :reduce="(val) => val.key" />
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <label>Not</label>
                    <b-form-textarea v-model="addNoteRequest.note" />
                </b-col>
            </b-row>
        </p>
        <p>
            <b-button block variant="primary" class="font-weight-bolder mb-12" @click="addNote">Ekle</b-button>
        </p>
    </b-modal>
</div>
</template>

<script>
import {
    BOverlay,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
    BButton,
    BTable,
    BAvatar,
    BFormInput,
    BModal,
    BFormTextarea,
    BFormCheckbox,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import XLSX from "xlsx";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
export default {
    components: {
        VueGoodTable,
        BFormTextarea,
        BModal,
        BFormInput,
        BOverlay,
        BProgressBar,
        BProgress,
        BTable,
        BAvatar,
        vSelect,
        AppTimeline,
        AppTimelineItem,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BImg,
        BNavbar,
        BNavbarToggle,
        BCollapse,
        BTabs,
        BNavItem,
        BButton,
        BFormCheckbox
    },
    watch: {
        selectedLesson: function (val) {
            this.childClass = [];
            this.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter((x) => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        filteredLesson: function (val) {
            this.getChildLesson();
            //this.refleshData();
        },
        filteredChildLesson: function (val) {
            this.refleshData();
        },
    },
    data() {
        return {
            loading: false,
            selectedMenu: 0,
            selectedLesson: "",
            childClassId: "",
            users: [],
            lessons: [],
            childClass: [],
            questionFields: [{
                    field: 'createdAt',
                    label: 'Kayıt',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Tarihe Göre Ara',
                    },
                },
                {
                    field: 'firstName',
                    label: 'İsim',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'İsme Göre Ara',
                    },
                },
                {
                    field: 'lastName',
                    label: 'Soyisim',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Soyisme Göre Ara',
                    },
                },
                { field: "action", label: "#" },
            ],
            selectedUser: {},
            videos: [],
            tests: [],
            lacks: [],
            stylabus: [],
            homeworks: [],
            exams: [],
            schedularHomeworks: [],
            systemMessages: [],
            filteredLesson: null,
            childLessons: [],
            filteredChildLesson: null,
            percent: 0,
            userStatus: "",
            notes: [],
            addNotePanel: false,
            addNoteRequest: {
                note: "",
                status: "normal",
            },
            noteStatus: [{
                    key: "Low",
                    label: "Düşük",
                },
                {
                    key: "Normal",
                    label: "Normal",
                },
                {
                    key: "Hight",
                    label: "Yüksek",
                },
            ],
            addSendMessageRequest: {
                text: '',
                isEmail: true,
                isSms: true,
                isUser: true,
                isGuardian: true
            },
            guardian: {
                firstName: "",
                lastName: "",
                gsmNumber: "",
                email: ""
            }
        };
    },
    created() {
        this.getLesson();
    },
    methods: {
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },
        async setPercent() {
            this.$confirm({
                auth: true,
                title: "Haftalık Ödev Gönderme",
                message: `Haftalık ödev göndermek istediğinizden eminmisiniz?`,
                button: {
                    no: "Hayır",
                    yes: "Evet",
                },
                callback: async (confirm, password) => {
                    if (confirm && password && password == "683091") {
                        await this.$http.get(
                            "Report/GenerateWeeklyHomeWork/" +
                            this.selectedUser.id +
                            "/" +
                            this.percent
                        );
                        await this.getHomeWorks();
                    }
                },
            });
        },
        async getChildLesson() {
            var lesson = await this.$http.get(
                "Teacher/ChildLessons/" +
                this.filteredLesson.lesson.id +
                "/" +
                this.filteredLesson.class.id
            );
            this.childLessons = lesson.data.data;
        },
        async getStudent() {
            var users = await this.$http.get(
                "Teacher/StudentsChildClass/" + this.childClassId.id
            );
            this.users = users.data.data;
        },
        async getDownload() {
            this.loading = true;
            var report = await this.$http.get(
                "Report/ReportDownload/" + this.childClassId.id
            );
            
            this.loading = false;
            window.open(report.data.data.downloadUrl, "_blank");
        },
        async getReport(user) {
            this.selectedUser = user;
            this.filteredLesson = null;
            this.filteredChildLesson = null;
            this.refleshData();
        },
        async getUserGuardian() {
            var guardian = await this.$http.get(
                "Report/UserGuardian/" + this.selectedUser.id
            );
            this.guardian = guardian.data.data;
        },
        async refleshData() {
            this.loading = true;

            this.getStatus();
            Promise.all([
                this.getUserGuardian(),
            this.getVideo(),
            this.getTest(),
            this.getLacks(),
            this.getStylabus(),
            this.getHomeWorks(),
            this.getExams(),
            this.getSystemMessages(),
            this.getNotes(),
            this.getSchedularHomeWork(),
            ]).then((values) => {
                this.loading = false;
            });


        },
        async getVideo() {
            var videos = await this.$http.post(
                "Report/VideoWatching/" + this.selectedUser.id, {
                    lessonId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.lesson.id,
                    classId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.class.id,
                    childLessonId: this.filteredChildLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredChildLesson.id,
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    videoId: "00000000-0000-0000-0000-000000000000",
                }
            );
            this.videos = videos.data.data;
        },
        async getTest() {
            var tests = await this.$http.post("Report/Test/" + this.selectedUser.id, {
                lessonId: this.filteredLesson == null ?
                    "00000000-0000-0000-0000-000000000000" : this.filteredLesson.lesson.id,
                classId: this.filteredLesson == null ?
                    "00000000-0000-0000-0000-000000000000" : this.filteredLesson.class.id,
                childLessonId: this.filteredChildLesson == null ?
                    "00000000-0000-0000-0000-000000000000" : this.filteredChildLesson.id,
                topicId: "00000000-0000-0000-0000-000000000000",
                videoGroupId: "00000000-0000-0000-0000-000000000000",
                videoId: "00000000-0000-0000-0000-000000000000",
            });
            this.tests = tests.data.data;
        },
        async getHomeWorks() {
            var tests = await this.$http.post(
                "Report/StudentHomeWork/" + this.selectedUser.id, {
                    lessonId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.lesson.id,
                    classId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.class.id,
                    childLessonId: this.filteredChildLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredChildLesson.id,
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    videoId: "00000000-0000-0000-0000-000000000000",
                }
            );
            this.homeworks = tests.data.data;
        },
        async getExams() {
            var tests = await this.$http.get("Report/Exam/" + this.selectedUser.id);
            this.exams = tests.data.data;
        },
        async getSystemMessages() {
            var tests = await this.$http.get(
                "Report/SenderHistory/" + this.selectedUser.id
            );
            this.systemMessages = tests.data.data;
        },
        async getStatus() {
            var tests = await this.$http.get("Report/Status/" + this.selectedUser.id);
            this.userStatus = tests.data;
        },
        async getSchedularHomeWork() {
            var tests = await this.$http.get("Report/SchedularHomeWorks/" + this.selectedUser.id);
            this.schedularHomeworks = tests.data.data;
        },
        async removeWeekly(id) {
            var status = await this.$http.delete("Report/SchedularHomeWork/" + id);
            this.getSchedularHomeWork();
        },
        async allRemoveWeekly() {
            var status = await this.$http.delete("Report/RemoveSchedularHomeWork/" + this.selectedUser.id);
            this.getSchedularHomeWork();
        },
        async getNotes() {
            var tests = await this.$http.get("Report/Notes/" + this.selectedUser.id);
            this.notes = tests.data.data;
        },
        async addNote() {
            await this.$http.post("Report/Note", {
                userId: this.selectedUser.id,
                ...this.addNoteRequest,
            });
            this.getNotes();
        },
        async sendMessage() {
            var response = await this.$http.post("Report/SendMessage", {
                studentId: this.selectedUser.id,
                ...this.addSendMessageRequest,
            });
            if (response.data.data) {
                alert("Gönderim Sağlandı");
            } else {
                alert("Bir Hata Oluştu")
            }
        },
        async getLacks() {
            var lacks = await this.$http.post(
                "Report/Lacks/" + this.selectedUser.id, {
                    lessonId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.lesson.id,
                    classId: this.filteredLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredLesson.class.id,
                    childLessonId: this.filteredChildLesson == null ?
                        "00000000-0000-0000-0000-000000000000" : this.filteredChildLesson.id,
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    videoId: "00000000-0000-0000-0000-000000000000",
                }
            );
            this.lacks = lacks.data.data;
        },
        async getStylabus() {
            var stylabus = await this.$http.get(
                "Report/StudentDiscontinuity/" + this.selectedUser.id
            );
            this.stylabus = stylabus.data.data;
        },
        getTestVariant(status) {
            switch (status) {
                case "HasResulted":
                    return "success";
                case "Waiting":
                    return "info";
                case "Processing":
                    return "warning";
                case "Done":
                    return "success";
                case "NotDone":
                    return "danger";
            }
        },
        getNoteStatus(status) {
            switch (status) {
                case "NORMAL":
                    return "success";
                case "LOW":
                    return "warning";
                case "HIGHT":
                    return "danger";
            }
        },
        async exportExcel() {
            var exportData = [];
            this.lacks.forEach((element) => {
                var row = {};
                row["Ders"] = element.lesson;
                row["Alt Ders"] = element.childLesson;
                row["Konu"] = element.topic;
                row["Video Grup"] = element.videoGroup;
                row["Kazanım"] = element.title;
                row["Eksik Oluşma Tarihi"] = element.createdAt;
                row["Eksik Giderme Tarihi"] = element.deletedAt;

                exportData.push(row);
            });

            let data = XLSX.utils.json_to_sheet(exportData);
            let wb = XLSX.utils.book_new();
            var fileName =
                this.selectedUser.firstName + " " + this.selectedUser.lastName;
            XLSX.utils.book_append_sheet(wb, data, fileName);

            await XLSX.writeFile(wb, fileName + ".xlsx");
        },
        openDetail(examId) {
            let routeData = this.$router.resolve({
                name: "exam-user-report",
                params: { userId: this.selectedUser.id, examId: examId },
            });
            window.open(
                routeData.href,
                "Deneme Sınavı Detayları",
                "height=800,width=800"
            );
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
